<template>
    <div class="login-wrap">
        <div class="content">
            <div class="left">
                <div class="login-form">
                    <div class="title" style="box-sizing: border-box;">
                        <h3>Hello!</h3>
                        <span class="welcome">欢迎登录</span>
                        <span class="welcome-title">&nbsp;{{appName}}</span>
                        <div style="padding: 0;margin: 0;font-size: 12px;width: 100%;display: flex;justify-content: flex-end;align-items: flex-start;" v-if="noValue.length>0">
                            <span style="margin-right: 40px;">{{noValue}}</span>
                        </div>
                    </div>
                    <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="100px" :label-position="labelPosition" class="demo-ruleForm">
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.username" @keyup.enter.native="onLogin('loginForm')"  placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item  prop="password">
                            <el-input v-model="loginForm.password" @keyup.enter.native="onLogin('loginForm')" type="password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button size="medium" class="student-btn" @click="onLogin('loginForm')">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="right"></div>
        </div>
<!--        <a href="https://beian.miit.gov.cn/" style="color: #333;margin-top: 50px;">闽ICP备15019684号 </a>-->
    </div>
</template>

<script>
import {config} from "@/utils/config";
    export default {
        name: 'Login',
        data() {
            return {
                appName: config.app_name,
                noValue: config.no,
                labelPosition: 'top',
                loginForm: {
                    username: '',
                    password: ''
                },
                rules: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur'},
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            onLogin(formName) {
                console.log(123);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('username', this.loginForm.username);
                        formData.append('password', this.loginForm.password);
                        this.$httpStudent.axiosPost(this.$api.login, formData, (res) => {
                            console.log(res)
                            if (res.code === 200) {
                                if(res.data.role === 4){
                                    this.$message({
                                        type: 'success',
                                        message: '学生登录成功！',
                                        duration: 1000,
                                    });
                                    localStorage.setItem('studentToken', res.data.token);
                                    localStorage.setItem('username', res.data.username);
                                    localStorage.setItem('name', res.data.name);
                                    localStorage.setItem('role', res.data.role);
                                    localStorage.setItem('adminIcon', res.data.head_portrait);
                                    this.$router.push('/student/works/index');
                                };
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: res.message
                                });
                            }
                        }, (err) => {
                            console.log(err);
                        })
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .login-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        /*background-image: url("../../assets/img/image/login-1.png");*/
        background-repeat: no-repeat;
        background-size: contain;
        overflow: auto;
    }
    .header {
        padding: 20px;
    }
    .content {
        width: 1000px;
        height: 540px;
        background-color: #fff;
        box-shadow: 1px -1px 15px -1px #ccc;
    }
    .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40%;
        height: 100%;
        float: left;
        background-color: #fff;
        border-radius: 0 4px 4px 0;
    }
    .right {
        width: 60%;
        height: 100%;
        float: right;
        background-image: url("../../assets/img/image/student-right.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px 0 0 4px;
    }
    .login-form {
        width:300px;
        height: 400px;
        border-radius: 0 4px 4px 0;
        margin: 60px auto 0;

        .title {
            margin-bottom: 22px;
            text-align: left;
            h3{
                margin: 10px 0;
                font-size: 24px;
                font-family: unset
            }
            .welcome{
                font-size: 22px;
                color: #969696;
            }
            .welcome-title{
                font-size: 19px;
                color: #1669FF;
            }
        }
        ::v-deep.el-input__inner{
            width: 248px;
            height: 38px;
            background: rgba(247,247,247,1);
            border-radius: 19px;
        }
        ::v-deep.el-form-item{
            margin-bottom: 30px;
        }
        ::v-deep.el-form-item__error{
            left: 12px;
        }
        .student-btn{
            width:100px;
            height:35px;
            background:linear-gradient(270deg,rgba(9,92,255,1) 0%,rgba(104,169,253,1) 100%);
            border-radius:18px;
            color: #fff;
            border: none;
        }
        .student-btn:hover{
            background:linear-gradient(320deg,rgba(9,92,255,1) 0%,rgba(104,169,253,1) 100%);
        }
    }

</style>
